@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

body {
  /* font-size: 16px; */
  background: #f1f5f9;
}
a.active {
  /* background-color: rgb(55 48 163); */
  /* background-color: rgba(255, 255, 255, 0.1); */
  background-color: rgb(255 255 255 / 0.1);
  opacity: 1;
}
.dropdown:hover .dropdown-menu {
  display: block;
}

@layer components {
  .btn-primary {
    @apply py-2 px-4 text-base capitalize font-medium  bg-gradient-to-r from-pink-500 to-orange text-white rounded-lg shadow-md hover:bg-gradient-to-b;
  }
}